import { submitUserQueryDetails } from "../../services/core-api-adapter";
import { setup, assign, fromPromise } from "xstate";

export const eventNames = {
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  TRY_AGAIN_BUTTON: "TRY_AGAIN_BUTTON",
  SUBMIT_BUTTON_CLICKED: "SUBMIT_BUTTON_CLICKED",
  LOG_ANOTHER_QUERY_BUTTON_CLICKED: "LOG_ANOTHER_QUERY_BUTTON_CLICKED",
};

interface Context {
  userQueryDetails: {
    firstName: string;
    feedbackMessage: string;
    phoneNumber: { globalSubscriberNumber: string; countryCode: string };
    querySourceURI: string;
    lastName?: string;
    emailAddress?: string;
  } | null;
}

const initialContextValues: Context = {
  userQueryDetails: null,
};

export const collectUserQueryStateMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addUserQueryDetailsToContext: assign(({ event }: any) => {
      return {
        userQueryDetails: event.input,
      };
    }),
    removeUserQueryDetailsFromContext: assign(() => {
      return {
        userQueryDetails: null,
      };
    }),
  },
  actors: {
    submitUserQueryDetails: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await submitUserQueryDetails({
          firstName: input.context.userQueryDetails?.firstName ?? "",
          lastName: input.context.userQueryDetails?.lastName ?? "",
          phoneNumber: {
            globalSubscriberNumber:
              input.context.userQueryDetails?.phoneNumber
                ?.globalSubscriberNumber ?? "",
            countryCode:
              input.context.userQueryDetails?.phoneNumber?.countryCode ?? "",
          },
          emailAddress: input.context.userQueryDetails?.emailAddress ?? "",
          feedbackMessage:
            input.context.userQueryDetails?.feedbackMessage ?? "",
          querySourceURI: input.context.userQueryDetails?.querySourceURI ?? "",
        });
      }
    ),
  },
  schemas: {
    events: {
      CONTINUE_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      SUBMIT_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      GO_HOME_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      LOG_ANOTHER_QUERY_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      TRY_AGAIN_BUTTON: {
        type: "object",
        properties: {},
      },
    },
  },
}).createMachine({
  context: initialContextValues,
  id: "collectUserQuery",
  initial: "collectingUserQueryDetails",
  states: {
    collectingUserQueryDetails: {
      on: {
        SUBMIT_BUTTON_CLICKED: {
          target: "submittingUserQueryDetails",
          actions: {
            type: "addUserQueryDetailsToContext",
          },
        },
      },
    },
    submittingUserQueryDetails: {
      invoke: {
        id: "submittingUserQueryDetails",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "success",
        },
        onError: {
          target: "error",
        },
        src: "submitUserQueryDetails",
      },
    },
    success: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
        LOG_ANOTHER_QUERY_BUTTON_CLICKED: {
          target: "collectingUserQueryDetails",
          actions: {
            type: "removeUserQueryDetailsFromContext",
          },
        },
      },
    },
    error: {
      on: {
        TRY_AGAIN_BUTTON: {
          target: "collectingUserQueryDetails",
        },
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    exit: {
      type: "final",
    },
  },
});
