import { Button, Stack, Typography } from "@mui/material";
import FullscreenBackground from "../../../FullscreenBackground";
import { useTranslation } from "react-i18next";
import IconLoader from "../../../IconLoader";
import { convertPxToRem } from "../../../../utils";
import theme from "../../../../theme";

interface CollectUserQuerySuccessProps {
  onLogAnotherQuery: Function;
  onGoHome: Function;
  queryLoggerFirstName?: string;
}

export default function CollectUserQuerySuccess({
  onLogAnotherQuery,
  onGoHome,
  queryLoggerFirstName,
}: CollectUserQuerySuccessProps) {
  const { t } = useTranslation();

  function onLogAnotherQueryHandler() {
    onLogAnotherQuery();
  }
  function onGoHomeHandler() {
    onGoHome();
  }

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      p={2}
      height="100vh"
    >
      <IconLoader
        icon="ChatWithTick"
        color={theme.palette.neutral[50] as any}
        sx={{
          fontSize: convertPxToRem(150),
          alignSelf: "center",
        }}
      />

      <Stack textAlign="center" spacing={4} py={4}>
        <Typography variant="h1" color="inherit">
          {t("CollectUserQuerySuccess.title", {
            firstName: queryLoggerFirstName,
          })}
        </Typography>

        <Stack>
          {(
            t<any, any, string[]>(`CollectUserQuerySuccess.body`, {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography
              variant="body1"
              color="inherit"
              key={String(text) + String(index)}
            >
              {text}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button onClick={onLogAnotherQueryHandler} color="info" fullWidth>
          {t("CollectUserQuerySuccess.logAnotherQueryLabel")}
        </Button>
        <Button
          onClick={onGoHomeHandler}
          color="info"
          variant="outlined"
          fullWidth
        >
          {t("CollectUserQuerySuccess.goHomeLabel")}
        </Button>
      </Stack>

      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
