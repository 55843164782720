import { TextareaAutosize as TextArea } from "@mui/base/TextareaAutosize";
import {
  Button,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../../../services/analytics-adapter";
import theme, { shadows, styleInputDefault } from "../../../../theme";
import FaceIconBrandLarge from "../../../../theme/icons/FaceIconBrandLarge";
import { convertPxToRem } from "../../../../utils";
import CircledBackButton from "../../../CircledBackButton";
import { isValid as isValidEmailAddress } from "../../../../utils/validators/EmailAddress/EmailAddress";
import { isValid as isValidInternationalPhoneNumber } from "../../../../utils/validators/InternationalPhoneNumber/InternationalPhoneNumber";
import InternationalPhoneNumberInput, {
  InternationalPhoneNumber,
} from "../../../InternationalPhoneNumberInput";
import MaxWidthContainer from "../../../MaxWidthContainer";
import { findCountryBasedOnCountryCode } from "../../../../store";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../../../services/feature-toggle-adapter";

interface CollectUserQueryFormProps {
  data?: {
    userDetails: {
      firstName: string;
      feedbackMessage: string;
      phoneNumber: InternationalPhoneNumber | null;
      querySourceURI: string;
      lastName?: string;
      emailAddress?: string;
    };
  };
  onSubmit: (data: {
    firstName: string;
    feedbackMessage: string;
    phoneNumber: InternationalPhoneNumber | null;
    querySourceURI: string;
    lastName?: string;
    emailAddress?: string;
  }) => void;
}

export default function CollectUserQueryForm({
  data,
  onSubmit,
}: CollectUserQueryFormProps) {
  const { t } = useTranslation();
  const defaultCountryCode = "ZA";
  const firstNameInputID = useId();
  const lastNameInputID = useId();
  const emailAddressInputID = useId();

  const [firstName, setFirstName] = useState(
    data?.userDetails?.firstName || ""
  );
  const [lastName, setLastName] = useState(data?.userDetails?.lastName || "");
  const [feedbackMessage, setFeedbackMessage] = useState(
    data?.userDetails?.feedbackMessage || ""
  );
  const [emailAddress, setEmailAddress] = useState(
    data?.userDetails?.emailAddress || ""
  );
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  function transformPhoneNumber(phoneNumber: InternationalPhoneNumber) {
    if (
      !isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_INTERNATIONAL_PHONE_NUMBER
      )
    ) {
      return phoneNumber;
    }

    const countryCode = findCountryBasedOnCountryCode(phoneNumber.countryCode);

    const transformedPhoneNumber: InternationalPhoneNumber = {
      countryCode: countryCode.id,
      globalSubscriberNumber: phoneNumber.globalSubscriberNumber.replace(
        countryCode.internationalDiallingCode,
        ""
      ),
    };

    return transformedPhoneNumber;
  }

  const [phoneNumber, setPhoneNumber] = useState<InternationalPhoneNumber>(
    data?.userDetails?.phoneNumber
      ? transformPhoneNumber(data.userDetails.phoneNumber)
      : {
          countryCode:
            data?.userDetails?.phoneNumber?.countryCode || defaultCountryCode,
          globalSubscriberNumber:
            data?.userDetails?.phoneNumber?.globalSubscriberNumber || "",
        }
  );
  const [userDetails, setUserDetails] = useState({
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    feedbackMessage,
    querySourceURI: data?.userDetails?.querySourceURI || "/",
  });

  useEffect(() => {
    setIsSubmitButtonEnabled(
      Boolean(firstName.length > 0) &&
        isValidInternationalPhoneNumber(phoneNumber?.globalSubscriberNumber) &&
        isValidEmailAddress(emailAddress) &&
        Boolean(feedbackMessage.length > 0)
    );

    setUserDetails({
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      feedbackMessage,
      querySourceURI: data?.userDetails?.querySourceURI || "/",
    });
  }, [
    phoneNumber,
    emailAddress,
    lastName,
    firstName,
    feedbackMessage,
    data?.userDetails?.querySourceURI,
  ]);

  function onFirstNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFirstName(value);
  }

  function onLastNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setLastName(value);
  }

  function onEmailAddressInput(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { value } = event.target;
    setEmailAddress(value);
  }

  function onSubmitHandle() {
    trackEvent({
      event: "action.querySubmitted",
    });

    onSubmit(userDetails);
  }

  return (
    <Stack spacing={4} height="100%" justifyContent="flex-end">
      <CircledBackButton showLabel />
      <form>
        <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 8 }}>
          <Stack spacing={2} sx={{ pb: 5 }}>
            <FaceIconBrandLarge />
            <Stack spacing={2}>
              <Typography variant="h3">
                {t("CollectUserQueryForm.title")}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={firstNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("FirstNameInput.label")}
              </Typography>
            </InputLabel>
            <TextField
              id={firstNameInputID}
              value={firstName}
              onInput={onFirstNameInput}
              inputProps={{
                autoComplete: "off",
                "aria-label": t("FirstNameInput.label"),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={lastNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("LastNameInput.label")}
              </Typography>
            </InputLabel>
            <TextField
              id={lastNameInputID}
              value={lastName}
              onInput={onLastNameInput}
              inputProps={{
                autoComplete: "off",
                "aria-label": t("LastNameInput.label"),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("CollectPhoneNumber.CellphoneNumberInputLabel")}
              </Typography>
            </InputLabel>
            <InternationalPhoneNumberInput
              value={phoneNumber || ""}
              onChange={(value: InternationalPhoneNumber) => {
                setPhoneNumber(value);
              }}
              size="small"
              inputStylesOverride={styleInputDefault}
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={emailAddressInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("common.emailAddressInputLabel")}
              </Typography>
            </InputLabel>
            <TextField
              id={emailAddressInputID}
              value={emailAddress}
              onInput={onEmailAddressInput}
              type="email"
            ></TextField>
          </Stack>

          <Stack spacing={1}>
            <Typography color="neutral.700" variant="body2" fontWeight={300}>
              {t("CollectUserQueryForm.queryLabel")}
            </Typography>
            <TextArea
              style={{
                marginTop: 8,
                borderRadius: 6,
                width: "100%",
                padding: 4,
                fontFamily: "inherit",
                fontSize: convertPxToRem(16),
                color: theme.palette.grey[700],
                border: `1px solid ${theme.palette.neutral[300]}`,
              }}
              value={feedbackMessage}
              onChange={(e: any) => {
                setFeedbackMessage(e.target.value);
              }}
              aria-label={t("CollectUserQueryForm.queryLabel")}
              minRows={6}
            />
          </Stack>
          <Stack spacing={1} py={4} textAlign="center">
            <Typography variant="h2" fontSize={convertPxToRem(16)}>
              {t("CollectUserQueryForm.availabilityTitle")}
            </Typography>
            <Typography>
              {t("CollectUserQueryForm.availabilityTimes")}
            </Typography>
          </Stack>
        </Stack>
      </form>
      <Stack
        direction="row"
        spacing={1}
        p={2}
        bgcolor="background.paper"
        width="100%"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        left="0"
        bottom="0"
        position="fixed"
        sx={{
          boxShadow: shadows.heavy,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
        }}
      >
        <MaxWidthContainer>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Button
              onClick={onSubmitHandle}
              color="primary"
              disabled={!isSubmitButtonEnabled}
              aria-label={t("common.submitButton")}
              fullWidth
            >
              {t("common.submitButton")}
            </Button>
          </Stack>
        </MaxWidthContainer>
      </Stack>
    </Stack>
  );
}
